import React from 'react';
import Typed from 'react-typed';
import { scroller } from 'react-scroll';

const Hero = () => {

  const handleGetStarted = () => {
    scroller.scrollTo('cardsSection', {
      duration: 800,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className="text-white w-full">
      <div className="max-w-[800px] min-h-[calc(100vh-81px)] w-full mx-auto text-center flex flex-col justify-center py-8">
        <p className="text-white font-bold mb-[37px] text-lg">Generate One World Chain Wallets in Bulk</p>
        <h1 className="md:text-[64px] sm:text-6xl text-4xl font-bold mb-5">Create One World Chain wallets in bulk quickly</h1>
        <div className="flex justify-center items-center mb-[30px]">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold">and</p>
          <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["easily", "conveniently", "efficiently"]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-white mb-[60px]">
          Simplifies the process of generating a series of One World Chain wallets with just a few clicks. It's quick, easy,
          and convenient.
        </p>
        <button
          onClick={handleGetStarted}
          className="bg-[#5337FE] w-[180px] rounded-md font-medium my-6 mx-auto py-3 text-white"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Hero;
