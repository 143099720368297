import React from 'react';
import { FaGithub, FaMedium, FaXTwitter } from "react-icons/fa6";
import logo from '../assets/logo.png';

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
      <div>
        {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>BULKWALLET.</h1> */}
        <img src={logo} alt="/" />
        <p className="py-5">
          Unlimited is a provider of blockchain services catering to the needs of the One World Chain ecosystem.
        </p>
        <p className="mb-5">
          One World Chain network is a permissionless and border-less publish ledger designed for next generation web3
          gaming.
        </p>
        <div className="flex gap-[10px] mb-6">
          <a
            href="https://x.com/OneWorldChain"
            target="_blank"
            className="w-[34px] h-[34px] rounded-[4px] flex justify-center items-center bg-[#5337FE] text-white"
            rel="noreferrer"
          >
            <FaXTwitter size={20} />
          </a>
          <a
            href="https://medium.com/@oneworldchain"
            target="_blank"
            className="w-[34px] h-[34px] rounded-[4px] flex justify-center items-center bg-[#5337FE] text-white"
            rel="noreferrer"
          >
            <FaMedium size={20} />
          </a>
          <a
            href="https://github.com/oneworldchain"
            target="_blank"
            className="w-[34px] h-[34px] rounded-[4px] flex justify-center items-center bg-[#5337FE] text-white"
            rel="noreferrer"
          >
            <FaGithub size={20} />
          </a>
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6">
        <div>
          <h6 className="font-medium text-white mb-5">Solutions</h6>
          <ul>
            <li className="py-2 text-sm">Analytics</li>
            <li className="py-2 text-sm">Marketing</li>
            <li className="py-2 text-sm">Commerce</li>
            <li className="py-2 text-sm">Insights</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-white mb-5">Support</h6>
          <ul>
            <li className="py-2 text-sm">Pricing</li>
            <li className="py-2 text-sm">Documentation</li>
            <li className="py-2 text-sm">Guides</li>
            <li className="py-2 text-sm">API Status</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-white mb-5">Company</h6>
          <ul>
            <li className="py-2 text-sm">About</li>
            <li className="py-2 text-sm">Blog</li>
            <li className="py-2 text-sm">Jobs</li>
            <li className="py-2 text-sm">Press</li>
            <li className="py-2 text-sm">Careers</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-white mb-5">Legal</h6>
          <ul>
            <li className="py-2 text-sm">Claim</li>
            <li className="py-2 text-sm">Policy</li>
            <li className="py-2 text-sm">Terms</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
